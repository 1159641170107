.swal2-container-custom {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.swal2-html-container-custom {
    margin: 0 !important;
}

.swal2-popup-custom {
    background: none !important;
    padding: 0 !important;
}

.swal2-popup-custom-confirm-container {
    background: none !important;
    padding: 0 !important;
}

.swal2-popup-custom-confirm {
    max-width: 420px;
}


.swal2-container-task {
    padding-top: 100px !important;
    padding-bottom: 100px !important;

    @media screen and (max-width: 425px) {
        padding: 0 !important;
    }
}

.swal2-html-container-task {
    margin: 0 !important;
}

.swal2-popup-task {
    background: none !important;
    padding: 0 !important;
}
